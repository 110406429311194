import img1 from '../assets/images/Icon1.jpeg';
import img2 from '../assets/images/VIcon-5.jpeg';
import img3 from '../assets/images/VIcon-3.jpeg';
import img4 from '../assets/images/Icon.jpg';

const StudentData = [
    {
      air: 1,
      name: "Kayra Joshi",
      classroom: "3 Year Classroom",
      image: img1,
    },
    {
      air: 2,
      name: "Shubham Naik",
      classroom: "4 Year Classroom",
      image: img2,
    },
    {
      air: 3,
      name: "Raghav Deshmukh",
      classroom: "4 Year Classroom",
      image: img3,
    },
    {
        air: 4,
        name: "Rohan Patil",
        classroom: "3 Year Classroom",
        image: img4,
      },
      
    // Add more students similarly
  ];
  
  export default StudentData;
  