import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Nav, Navbar as BootstrapNavbar, Container } from 'react-bootstrap';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from '../assets/images/PDL-logo.jpg';

const Navbar = () => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <BootstrapNavbar expand="lg" className={`navbar sticky-top ${scrolled ? 'scrolled' : ''}`}>
      <Container fluid>
        <BootstrapNavbar.Brand as={Link} to="/" className="navbar-logo">
          <img src={logo} alt="PDL Logo" className="logo" />
          <div className="logo-text">PDL SCHOLAR'S ACADEMY</div>
        </BootstrapNavbar.Brand>
        <BootstrapNavbar.Toggle aria-controls="basic-navbar-nav" />
        <BootstrapNavbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link as={Link} to="/">Home</Nav.Link>
            <Nav.Link as={HashLink} to="/Honors">Honors</Nav.Link>
            <NavDropdown title="Courses" id="basic-nav-dropdown">
              <NavDropdown.Item as={Link} to="/UniqueCourses">Unique Courses</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/CompetitiveExams">Competitive Exams</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/SmartCourses">Smart Courses</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link as={HashLink} to="/BatchesResults">Results</Nav.Link>
            <Nav.Link as={Link} to="/Event">Events</Nav.Link>
            <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
          </Nav>
        </BootstrapNavbar.Collapse>
      </Container>
    </BootstrapNavbar>
  );
};

export default Navbar;

