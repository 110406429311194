import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1 className="privacy-heading">Privacy Policy</h1>
      {/* <p className="last-updated">Last updated: March 27, 2025</p> */}
      <p>
        This Privacy Policy describes Our policies and procedures on the
        collection, use and disclosure of Your information when You use the
        Service and tells You about Your privacy rights and how the law protects
        You.
      </p>

      <p>
        We use Your Personal data to provide and improve the Service. By using
        the Service, You agree to the collection and use of information in
        accordance with this Privacy Policy.
      </p>

      <h4>Interpretation and Definitions</h4>
      <h5>Interpretation</h5>
      <p>
        The words of which the initial letter is capitalized have meanings
        defined under the following conditions. The following definitions shall
        have the same meaning regardless of whether they appear in singular or
        in plural.
      </p>

      <h5>Definitions</h5>
      <ul>
        <li>
          <strong>Account:</strong> A unique account created for You to access
          our Service.
        </li>
        <li>
          <strong>Affiliate:</strong> An entity that controls, is controlled by
          or is under common control with a party.
        </li>
        <li>
          <strong>Company:</strong> PDL INTELLIGENCE WORLD PVT. LTD.
        </li>
        <li>
          <strong>Cookies:</strong> Small files placed on Your computer to track
          website activities.
        </li>
        <li>
          <strong>Device:</strong> Any device that can access the Service such
          as a computer or a mobile device.
        </li>
        <li>
          <strong>Personal Data:</strong> Any information that relates to an
          identified or identifiable individual.
        </li>
        <li>
          <strong>Service:</strong> Refers to the Website.
        </li>
      </ul>

      <h4>Collecting and Using Your Personal Data</h4>
      <h5>Types of Data Collected</h5>
      <h6>Personal Data</h6>
      <p>
        While using Our Service, We may ask You to provide Us with certain
        personally identifiable information, including but not limited to:
      </p>
      <ul>
        <li>Email address</li>
        <li>First name and last name</li>
        <li>Phone number</li>
        <li>Address, State, ZIP/Postal code, City</li>
      </ul>

      <h4>Use of Your Personal Data</h4>
      <p>
        The Company may use Personal Data for the following purposes:
      </p>
      <ul>
        <li>To provide and maintain our Service.</li>
        <li>To manage Your Account.</li>
        <li>To contact You regarding updates or offers.</li>
        <li>To manage Your requests.</li>
      </ul>

      <h4>Security of Your Personal Data</h4>
      <p>
        The security of Your Personal Data is important to Us, but no method of
        transmission over the Internet is 100% secure. While We strive to use
        commercially acceptable means to protect Your Personal Data, We cannot
        guarantee its absolute security.
      </p>

      <h4>Contact Us</h4>
      <p>If you have any questions about this Privacy Policy, You can contact us:</p>
      <ul>
        <li>Email: Pdlscholarsacademy@gmail.com</li>
        <li>Phone number: 90111 99796</li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
