import React from "react";
import "./ResultPage2.css"; // Importing the CSS file for styling
import StudentData from "./StudentData2"; // Importing mock student data

const ResultsPage2 = () => {
  return (
    <div className="results-page-container">
      <h1 className="results-title"> Abacus 2024 Results </h1>
      <div className="student-grid">
        {StudentData.map((student, index) => (
          <div key={index} className="student-card">
            <img
              src={student.image}
              alt={`${student.name} AIR ${student.air}`}
              className="student-photo"
            />
            <h3 className="student-rank">AIR {student.air}</h3>
            <p className="student-name">{student.name}</p>
            <p className="student-details">{student.classroom}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResultsPage2;
