// src/components/Footer.js
import React from 'react';
import logo from '../assets/images/logo removebg.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="footer" id="contact">
      <div className="footer-content">
        <div className="footer-contact">
          <p>Contact Us: <a href="tel:+919011199796">+91 90111 99796</a> | <a href="mailto:Pdlscholarsacademy@gmail.com">Pdlscholarsacademy@gmail.com</a></p>
          <p>Address: 15, Shikshak Colony, Prashant Nagar, Garden Road, Near LIC Colony, Rukhmini Nagar, Amravati, Maharashtra 444606.</p>
        </div>
        <div className="footer-links">
          <Link to="/TermsConditions">Terms & Conditions</Link>
          <span>|</span>
          <Link to="/PrivacyPolicy">Privacy Policy</Link>
        </div>
        <div className="footer-designed">
          <p>© {new Date().getFullYear()} PDL INTELLIGENCE WORLD PVT. LTD.. All Rights Reserved.</p>
          <p>Designed and Managed by</p>
          <a href="https://bizonance.in">
            <img src={logo} alt="Bizonance Logo" className="footer-logo" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
