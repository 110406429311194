import React from "react";
import "./Result.css";
import jeeResults from "../assets/images/Icon.jpg";
import neetResults from "../assets/images/VIcon-7.jpeg";
import olympiadResults from "../assets/images/VIcon-2.jpeg";

const Results = () => {
  return (
  <div>
    <h2 className="results-title">OUTSTANDING RESULTS</h2>
    <div className="results-container">
      {/* <div className="results-cards"> */}
        {/* JEE Advanced Results */}
        <div className="result-card">
          <div className="result-header"> IAS Junior 2024 Results</div>
          <div className="result-students">
            <div className="student">
              <img src={jeeResults} alt="JEE AIR 25" className="student-img" />
              <h4>AIR 25</h4>
              <p>Rishi Shekher</p>
              <p>(2 Year Classroom)</p>
            </div>
            <div className="student">
              <img src={jeeResults} alt="JEE AIR 67" className="student-img" />
              <h4>AIR 67</h4>
              <p>Krishna Sai</p>
              <p>(2 Year Classroom)</p>
            </div>
            <div className="student">
              <img src={jeeResults} alt="JEE AIR 78" className="student-img" />
              <h4>AIR 78</h4>
              <p>Abhishek Jain</p>
              <p>(4 Year Classroom)</p>
            </div>
          </div>
          <a href="/BatchesResults" className="see-results">See all results</a>
        </div>

        {/* NEET Results */}
        <div className="result-card">
          <div className="result-header"> Abacus 2024 Results</div>
          <div className="result-students">
            <div className="student">
              <img src={neetResults} alt="NEET AIR 1" className="student-img" />
              <h4>AIR 1</h4>
              <p>Mridul Manya</p>
              <p>(5 Year Classroom)</p>
            </div>
            <div className="student">
              <img src={neetResults} alt="NEET AIR 1" className="student-img" />
              <h4>AIR 9</h4>
              <p>Ayush Nugariya</p>
              <p>(2 Year Classroom)</p>
            </div>
            <div className="student">
              <img src={neetResults} alt="NEET AIR 1" className="student-img" />
              <h4>AIR 17</h4>
              <p>Sagar Dutta</p>
              <p>(2 Year Classroom)</p>
            </div>
          </div>
          <a href="/ResultPage2" className="see-results">See all results</a>
        </div>

        {/* Olympiads Results */}
        <div className="result-card">
          <div className="result-header">Olympiads & Scholarship Exam Results</div>
          <div className="result-students">
            <div className="student">
              <img src={olympiadResults} alt="Olympiad 2023" className="student-img" />
              <h5>Zaman Hussain</h5>
              <p>NSEA/CP RMO-2023</p>
            </div>
            <div className="student">
              <img src={olympiadResults} alt="Olympiad 2023" className="student-img" />
              <h5>Mohit Shekhar</h5>
              <p>IOQM 2023</p>
            </div>
            <div className="student">
              <img src={olympiadResults} alt="Olympiad 2023" className="student-img" />
              <h5>Piyush Dhakar</h5>
              <p>NSEJS 2023</p>
            </div>
          </div>
          <a href="/ResultPage3" className="see-results">See all results</a>
        </div>
      </div>
    {/* </div> */}
  </div>
  );
};

export default Results;
