import React from 'react';

import centerImage from '../assets/images/center.jpg';

const Centers = () => {
  return (
    <div className="centers-container" id="centers">
      <div className="content">
        <h4>We are in your city now</h4>
        <h1>Discover <span>Offline Centres</span></h1>
        <ul>
          <li>⭐ Visit your nearest centre for a free career counselling session</li>
          <li>⭐ Get personalized expert advice in choosing the right career path</li>
          <li>⭐ Get industry-updated insights every week from domain experts</li>
        </ul>
        <div className="buttons">
          <button className="book-visit">Book a Visit</button>
          <button className="explore-centers">Explore Offline Centres</button>
        </div>
      </div>
      <div className="image-container">
        <img src={centerImage} alt="Center" />
        <div className="play-buttonh">
          {/* <button>▶</button> */}
        </div>
      </div>
    </div>
  );
};

export default Centers;
