import React from "react";
import "./Honors.css";
// import { FaAward, FaBriefcase, FaHandsHelping, FaMedal, FaStar, } from "react-icons/fa";
import DirectorImage from "../assets/images/StudentC.webp";
// import SonImage from "../assets/images/StudentA.png";

const HonorsTreeNew = () => {
  return (
    <div className="honors-container">
      {/* Floating Background Elements */}
      <div className="floating-bg">
        <div className="floating-circle c1"></div>
        <div className="floating-circle c2"></div>
        <div className="floating-circle c3"></div>
      </div>

      <div className="main-header">
        <h1>
          <span className="gradient-text">Honors & Achievements</span>
          {/* <span className="gradient-text">Achievements</span> */}
        </h1>
        <div className="deco-line"></div>
      </div>

      {/* Director's Section */}
      <div className="director-section">
        <div className="profile-card">
          <div className="hexagon-wrapper">
            <div className="hexagon">
              <img src={DirectorImage} alt="Director" />
            </div>
          </div>
          <h3 className="name-tag">Director's Legacy</h3>
          <div className="achievement-cloud">
            <div className="cloud-card">
              {/* <FaAward className="cloud-icon" /> */}
              <h4>Record Holder</h4>
              <p>शिक्षारत्न पुरस्कार by Praninji Patel Sir</p>
              <p>India & Asia Book of Records</p>
              <p>International Book of Records for 3100+ student's drawing competition</p>
            </div>
            <div className="cloud-card">
              {/* <FaStar className="cloud-icon" /> */}
              <h4>Teaching Excellence</h4>
              <p>Teachers Achievement Award (2018-2024)</p>
              <p>Best Academy Award by Silverzone International Olympiad, Delhi</p>
            </div>
            <div className="cloud-card">
              {/* <FaBriefcase className="cloud-icon" /> */}
              <h4>Leadership</h4>
              <p>Founded PDL Scholars Academy in 2015</p>
              <p>2500+ students trained, 7 franchises in Maharashtra</p>
            </div>
          </div>
        </div>

        {/* Experience Timeline */}
        <div className="timeline-wrapper">
          <div className="timeline">
            <div className="timeline-item">
              <div className="timeline-marker"></div>
              <div className="timeline-content">
                <h4>Bhartiya Airtel</h4>
                <p>Team Leader (2008-2009)</p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-marker"></div>
              <div className="timeline-content">
                <h4>Perfect Poly Pack Pvt. Ltd.</h4>
                <p>Administrator (2009-2010)</p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-marker"></div>
              <div className="timeline-content">
                <h4>Nagpur Municipal Corporation</h4>
                <p>Jr. Engineer (2010-2011)</p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-marker"></div>
              <div className="timeline-content">
                <h4>Brijlal Biyani Science College</h4>
                <p>Lecturer (5 years)</p>
              </div>
            </div>
            <div className="timeline-item">
              <div className="timeline-marker"></div>
              <div className="timeline-content">
                <h4>Started PDL Scholar's Academy</h4>
                <p>Since 2015</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Social Contributions */}
      <div className="contribution-wall">
        <div className="contribution-card">
          <div className="card-header">
            {/* <FaHandsHelping className="contribution-icon" /> */}
            <h3>Social Impact</h3>
          </div>
          <ul className="contribution-list">
            <li>Secretary at JCI Amravati Lady Lo (2023)</li>
            <li>Supporting 89 students at Bhilkheda, Chikhaldara school</li>
            <li>Providing stationary, clothes, sports equipment, and water purifiers</li>
            <li>Active in JCI Amravati Maratha Seva Sangha, Jijau Sanstha, and Vrukshavalli Sanstha</li>
          </ul>
        </div>
      </div>

      {/* Son's Achievements */}
      <div className="son-section">
        <div className="son-header">
          <div className="sparkle"></div>
          <h2>Son's Achievements</h2>
          <div className="sparkle"></div>
        </div>
        
        <div className="trophy-case">
          <div className="trophy-card gold">
            {/* <FaMedal className="trophy-icon" /> */}
            <h4>National Abacus Champion</h4>
            <p>1st Place</p>
          </div>
          <div className="trophy-card silver">
            {/* <FaMedal className="trophy-icon" /> */}
            <h4>Vedic Maths Olympiad</h4>
            <p>2nd Rank</p>
          </div>
          <div className="trophy-card emerald">
            {/* <FaMedal className="trophy-icon" /> */}
            <h4>Science Talent Search</h4>
            <p>Top 5 in State</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HonorsTreeNew;
