import React, { useState } from "react";
import "./BatchesResults.css";

// Function to generate random data
const generateRandomData = () => {
  const batches = [
    { id: 1, heading: "IAS Batch 2024" },
    { id: 2, heading: "Vedic Maths" },
    { id: 3, heading: "UPSC Foundation" },
    { id: 4, heading: "CAT Preparation" },
  ];

  const names = [
    "Aarav Sharma",
    "Neha Gupta",
    "Rohan Singh",
    "Priya Patel",
    "Vikram Joshi",
    "Ananya Reddy",
    "Kunal Mehta",
    "Sanya Malhotra",
  ];

  const scores = ["AIR 1", "AIR 2", "AIR 3", "90%", "95%", "99%", "9.8 CGPA"];
  const years = ["2021", "2022", "2023", "2024"];

  return {
    message: "Batch Retrieved",
    batch: batches.map((batch) => ({
      ...batch,
      result: Array.from({ length: Math.floor(Math.random() * 6) + 3 }, () => ({
        id: Math.random().toString(36).substr(2, 9),
        year: years[Math.floor(Math.random() * years.length)],
        media: Math.random() > 0.5 ? `https://picsum.photos/200/200?random=${Math.floor(Math.random() * 1000)}` : null,
        title: names[Math.floor(Math.random() * names.length)],
        score: scores[Math.floor(Math.random() * scores.length)],
      })),
    })),
  };
};

const Results = ({ data = generateRandomData() }) => {
  const [selectedYear, setSelectedYear] = useState("All");
  const [selectedBatch, setSelectedBatch] = useState("All");
  const [showSidebar, setShowSidebar] = useState(false);

  // Extract unique years and batches
  const allYears = [...new Set(data.batch.flatMap((batch) => batch.result.map((result) => result.year)))].sort(
    (a, b) => b - a,
  );

  const batches = data.batch.map((batch) => batch.heading);

  // Filter and group data
  const filteredBatches = data.batch.filter((batch) => {
    if (selectedBatch === "All") return true;
    return batch.heading === selectedBatch;
  });

  const groupedData = filteredBatches.map((batch) => {
    const yearGroups = batch.result.reduce((acc, result) => {
      if (selectedYear !== "All" && result.year !== selectedYear) return acc;

      if (!acc[result.year]) {
        acc[result.year] = [];
      }
      acc[result.year].push(result);
      return acc;
    }, {});

    return {
      ...batch,
      yearGroups: Object.entries(yearGroups).sort(([a], [b]) => b.localeCompare(a)),
    };
  });

  return (
    <div className="results-container">
      {/* Sidebar */}
      <aside className={`sidebar ${showSidebar ? "active" : ""}`}>
        <h2 className="sidebar-title">Filters</h2>

        <div className="filter-group">
          <label className="filter-label">Year</label>
          <div className="select-wrapper">
            <select
              value={selectedYear}
              onChange={(e) => setSelectedYear(e.target.value)}
              className="filter-select"
            >
              <option value="All">All Years</option>
              {allYears.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="filter-group">
          <label className="filter-label">Batch</label>
          <div className="select-wrapper">
            <select
              value={selectedBatch}
              onChange={(e) => setSelectedBatch(e.target.value)}
              className="filter-select"
            >
              <option value="All">All Batches</option>
              {batches.map((batch) => (
                <option key={batch} value={batch}>
                  {batch}
                </option>
              ))}
            </select>
          </div>
        </div>
      </aside>

      {/* Results Section */}
      <div className="results-section">
        {groupedData.length === 0 ? (
          <div className="no-results">
            <h3>No results found</h3>
            <p>Try changing your filters</p>
          </div>
        ) : (
          groupedData.map((batch) => (
            <div key={batch.id} className="batch-card">
              <h2 className="batch-heading">{batch.heading}</h2>

              {batch.yearGroups.length === 0 ? (
                <div className="no-results">
                  <p>No results for this batch with the selected filters</p>
                </div>
              ) : (
                batch.yearGroups.map(([year, results]) => (
                  <div key={year} className="year-section">
                    <div className="year-badge">{year}</div>
                    <div className="results-grid">
                      {results.map((result) => (
                        <div key={result.id} className="result-card">
                          <div className="result-content">
                            {result.media ? (
                              <div className="img-container">
                                <img
                                  src={result.media || "/placeholder.svg"}
                                  alt={result.title}
                                  className="result-img"
                                />
                              </div>
                            ) : (
                              <div className="avatar-placeholder">
                                {result.title
                                  .split(" ")
                                  .map((name) => name[0])
                                  .join("")}
                              </div>
                            )}
                            <div className="result-score">{result.score}</div>
                            <h3 className="result-title">{result.title}</h3>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))
              )}
            </div>
          ))
        )}
      </div>

      {/* Mobile Toggle Button */}
      <button className="mobile-filter-toggler" onClick={() => setShowSidebar(!showSidebar)}>
        ⚙️
      </button>
    </div>
  );
};

export default Results;