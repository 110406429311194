import React from "react";
import "./TestimonialsPage.css";
import StudentData from "./StudentData2";

const ResultsPage2 = () => {
  return (
    <div className="results-page-container">
      <h1 className="results-title"> TESTIMONIALS </h1>
      <div className="student-grid">
        {StudentData.map((student, index) => (
          <div key={index} className="student-card">
            <img
              src={student.image}
              alt={`${student.name}`}
              className="student-photo"
            />
            <h3 className="student-name">{student.name}</h3>
            <p className="student-details">{student.testimonial}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ResultsPage2;
