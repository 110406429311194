import React from 'react';
import './Contact.css';
const Contact = () => {
  return (
    <section className="contact-section" id="contact">
      <h2 className="contact-title">CONTACT US</h2>
      <p className="contact-subtitle">
        Reach out to us at any of our locations
      </p>
      <div className="contact-grid">
        <div className="contact-card">
          <div className="icon">🏫</div> {/* Add icons for each section */}
          <h4>Office Address:</h4>
          <p>House No.15, Shikshak Colony, Prashant Nagar, Garden Road, Near LIC Colony, Rukhmini Nagar, Amravati, Maharashtra 444606.</p>
        </div>
        <div className="contact-card">
          <div className="icon">🏢</div>
          <h4>Headquarter Address:</h4>
          <p>House No.15, Shikshak Colony, Prashant Nagar, Garden Road, Near LIC Colony, Rukhmini Nagar, Amravati, Maharashtra 444606.</p>
        </div>
        <div className="contact-card">
          <div className="icon">📞</div>
          <h4>Mobile No. & Mail ID:</h4>
          <p>+91 90111 99796 <br />
             Pdlscholarsacademy@gmail.com
          </p>
        </div>
      </div>
    </section>
  );
};

export default Contact;
