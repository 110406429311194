import React from 'react';
import './TermsConditions.css';

const TermsConditions = () => {
  return (
    <div className="terms-container">
      <h2 className="terms-title">TERMS & CONDITIONS</h2>

      <h3 className="terms-subtitle">1. Introduction</h3>
      <p>Welcome to PDL INTELLIGENCE WORLD PVT. LTD.. By accessing our website and enrolling in our courses, you agree to abide by the following terms and conditions.</p>

      <h3 className="terms-subtitle">2. Payment and Refund Policy</h3>
      <ul className="terms-list">
        <li>All fees must be paid in full before accessing any course or service.</li>
        <li>The fees which are paid are non-refundable.</li>
        <li>Refunds will be issued only in cases of course cancellation by the academy.</li>
        <li>Any chargebacks or payment disputes must be communicated with us directly before initiating any claim with the payment provider.</li>
        <li>In case of COVID-19 pandemic or technical issues, the organization has the right to reschedule the classes (online/offline).</li>
      </ul>

      <h3 className="terms-subtitle">3. Payment of Fees</h3>
      <ul className="terms-list">
        <li>Students must pay tuition and other applicable fees as per the agreed schedule.</li>
        <li>Late payment may result in penalties or restricted access to courses.</li>
        <li>We accept payments through secure payment methods.</li>
      </ul>

      <h3 className="terms-subtitle">4. Student Conduct</h3>
      <ul className="terms-list">
        <li>Students are expected to maintain professionalism and respectful behavior in both online and offline interactions.</li>
        <li>Any form of cheating, plagiarism, or misconduct will result in disciplinary action, which may include suspension or expulsion.</li>
        <li>Any misbehavior will be strictly taken care of, and the organization has the right to remove the student from the workshop with no refund of fees.</li>
        <li>Punctuality is a must; students must arrive at the venue at least 15 minutes prior to the scheduled time.</li>
        <li>In case of any illness, students won’t be allowed to attend the class, and the fees won’t be refundable.</li>
        <li>Any damage caused at the Centre by a student must be paid for by the student.</li>
      </ul>

      <h3 className="terms-subtitle">5. Parent and Guardian Conduct</h3>
      <ul className="terms-list">
        <li>Parents and guardians must communicate respectfully with staff and other students.</li>
        <li>Any harassment or inappropriate behavior towards staff or students may result in restricted access to academy services.</li>
      </ul>

      <h3 className="terms-subtitle">6. Reservation of Rights</h3>
      <ul className="terms-list">
        <li>We reserve the right to update these terms and conditions at any time.</li>
        <li>We hold the right to refuse service to any student or guardian who violates these terms.</li>
        <li>The academy reserves the right to make changes to class schedules, instructors, and locations as needed.</li>
      </ul>

      <h3 className="terms-subtitle">7. Dispute Resolution</h3>
      <ul className="terms-list">
        <li>Any legal disputes shall be resolved through arbitration in Amravati, Maharashtra.</li>
        <li>If arbitration is unsuccessful, legal action shall be taken in the courts of Amravati, Maharashtra.</li>
        <li>All disputes must be communicated in writing before seeking external legal action.</li>
      </ul>

      <p className="terms-footer">
        By using our services, you agree to these terms and conditions. If you have any questions, please contact us at +91 90111 99796 or pdlscholarsacademy@gmail.com.
      </p>
    </div>
  );
};

export default TermsConditions;
